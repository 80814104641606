const modalStyle = {
  overlay: {
    background: '#000',
  },
  modal: {
    background: '#000',
    height: '100%',
    margin: '0',
    maxWidth: 'unset',
    padding: '0',
    width: '100%',
  },
  content: {
    background: '#000',
    border: 'none',
    borderRadius: '0',
    inset: '0',
    margin: '0',
    padding: '0',
  },
}

export default modalStyle
