import React from 'react'

const TextFilter = props => {
  const {
    content,
    className = '',
    singleLayer = false,
    textWhite = false,
    isOnMobileToo = false,
  } = props
  const contentTrimmed = content.trim()
  return (
    <span
      className={`text-filter ${className} ${isOnMobileToo ? 'is-on-mobile-too' : ''} ${
        textWhite ? 'text-white' : ''
      }`}
    >
      <span>{contentTrimmed}</span>
      {!singleLayer && (
        <span className={`text-filter__foreground ${className}`} aria-hidden="true">
          {contentTrimmed}
        </span>
      )}
      <span className={`text-filter__background ${className}`} aria-hidden="true">
        {contentTrimmed}
      </span>
    </span>
  )
}

export default TextFilter
