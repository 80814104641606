import React from 'react'
import BasePortableText from '@sanity/block-content-to-react'
import serializers from './serializers'
import clientConfig from '../../client-config'
import { addColon } from '../lib/helpers'

function ColumnInfoEntries({ entries }) {
  return (
    <dl className="column-info-entries">
      {entries &&
        entries.map((entry, entryIndex) => (
          <div className="column-info-entries__entry mt-1em-m" key={entryIndex}>
            {entry.title && (
              <dt className="column-info-entries__entry__title text-xs text-caps">
                {addColon(entry.title)}
              </dt>
            )}
            <dd className="column-info-entries__entry__body text-m">
              <BasePortableText
                blocks={entry.body}
                serializers={serializers}
                {...clientConfig.sanity}
              />
            </dd>
          </div>
        ))}
    </dl>
  )
}

export default ColumnInfoEntries
