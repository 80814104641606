import React, { useRef, useEffect } from 'react'

const delay = 1000/60

function Slider(props) {
  const {className=""} = props
  const containerRef = useRef()
  const x = useRef(0)
  const lastTouchTime = useRef(0)
  const isTouching = useRef(false)
  const interval = useRef()

  useEffect(() => {
    return () => {
      clearInterval(interval.current)
    }
  }, [])

  function handleOnMouseEnter() {
    clearInterval(interval.current)
    if (!isTouching.current) {
      interval.current = setInterval(() => scroller(), delay)
    }
  }

  function handleOnMouseMove(e) {
    if (Date.now() - lastTouchTime.current > 500) {
      isTouching.current = false
    }
    const { clientX } = e
    const half = containerRef.current.offsetWidth / 2
    let currentX = (clientX - half) / half
    currentX = (Math.abs(currentX) / 2) * (currentX > 0 ? 1 : -1) // cos to get nice exponentional curve
    currentX = currentX * Math.abs(currentX) // power of 2 to make the speeding more exponentional
    x.current = Math.sin(currentX * Math.PI) * 100 // 100 is the maximum speed
  }

  function handleOnMouseLeave() {
    clearInterval(interval.current)
    interval.current = setInterval(() => {
      x.current *= 0.9
      containerRef.current.scrollBy(x.current, 0)
      if (Math.abs(x.current.toFixed(2)) === 0) {
        clearInterval(interval.current)
      }
    }, 35)
  }

  function handleOnTouchStart() {
    lastTouchTime.current = Date.now()
    isTouching.current = true
  }

  function scroller() {
    containerRef.current.scrollBy(x.current, 0)
  }


  return (
    <div
      ref={containerRef}
      className={`slider is-flex ${className}`}
      onMouseEnter={handleOnMouseEnter}
      onMouseMove={handleOnMouseMove}
      onMouseLeave={handleOnMouseLeave}
      onTouchStart={handleOnTouchStart}
    >
      {props.children}
    </div>
  )
}

export default Slider
