import React from 'react'

function WarpLogo(props) {
  const {color="black"} = props
  return (
    <svg className="warp-logo" width="74" height="40" viewBox="0 0 74 40" fill="none">
    <path d="M23.8421 33.3097C26.0148 32.7042 28.3205 32.2574 30.7128 31.9752C30.3545 30.7818 30.0483 29.5472 29.7998 28.2656L21.3862 29.4296C22.0912 30.8288 22.9002 32.1281 23.8421 33.3097Z" fill={color}/>
    <path d="M19.7569 29.6531L12.0137 30.723C13.6374 32.5102 15.6599 34.1034 17.9887 35.4497C19.312 34.8324 20.7219 34.2739 22.207 33.7977C21.3402 32.6454 20.0458 30.2057 19.7569 29.6531Z" fill={color}/>
    <path d="M23.2472 35.0618C22.0106 35.438 20.8087 35.8848 19.6992 36.361C22.3053 37.6602 25.3044 38.6655 28.4479 39.2946C26.5237 38.2129 24.7381 36.7431 23.2472 35.0618Z" fill={color}/>
    <path d="M36.3931 31.6519C37.7452 31.6519 39.0743 31.7048 40.3802 31.8106C40.883 30.1822 41.2875 28.4538 41.5822 26.6431L31.3657 28.054C31.6258 29.3591 31.9378 30.6172 32.3134 31.8165C33.654 31.7107 35.0178 31.6519 36.3931 31.6519Z" fill={color}/>
    <path d="M41.9868 31.9693C43.1888 32.1045 44.3676 32.2867 45.5175 32.5042C46.6674 32.7218 47.7885 32.9863 48.8748 33.2861C50.7702 30.9757 52.1224 28.3067 53.0932 25.0498L43.2176 26.4137C43.1888 26.6195 43.1541 26.8252 43.1194 27.031C42.8305 28.7417 42.4607 30.3937 41.9868 31.9693Z" fill={color}/>
    <path d="M33.2557 17.1663L31.6724 20.7759L33.0188 20.5995L33.2557 17.1663Z" fill={color}/>
    <path d="M17.353 17.1192L17.2489 21.8811L19.2599 17.0133L21.4153 16.8722V21.5107L23.276 16.7488L26.0612 16.5783L22.5305 24.75L19.6875 25.0851L19.3928 21.5107L17.8268 25.3084L15.1224 25.6377L14.9491 17.2661L17.353 17.1192ZM31.1868 16.255L35.8038 15.9551L35.5958 23.1568L32.8221 23.4919L32.9261 22.0574L30.9845 22.3396L30.2796 23.8093L27.3209 24.1738L31.1868 16.255ZM41.2183 15.6906L45.0206 15.4437C46.3786 15.3555 47.3956 15.6553 47.3956 16.8781C47.3956 17.9833 46.5519 18.6124 45.6563 18.9651C46.4479 18.8946 46.7946 19.1239 46.7946 19.5942C46.7946 19.7705 46.263 21.9281 46.263 21.9281L43.5413 22.2749L43.9574 20.4172C44.0614 19.9469 43.8707 19.7705 43.4604 19.8058L42.6861 19.8764L42.0331 22.4513L39.2479 22.804L41.2183 15.6906ZM52.0184 15.0909L56.0461 14.8087C57.2885 14.7206 58.4037 15.1438 58.4037 16.349C58.4037 17.6953 57.3 18.8534 55.2544 19.0415L53.6018 19.2003L53.1395 21.1638L50.4871 21.493L52.0184 15.0909ZM55.6011 23.292L53.648 28.4066L74 19.2708L64.9508 19.9998L69.1229 11.6165L19.2368 14.1738L22.8368 6.89575L0 18.63L8.17086 17.8658L1.65844 30.7935L55.6011 23.292Z" fill={color}/>
    <path d="M50.5044 33.7683C52.0126 34.2562 53.4515 34.8147 54.7978 35.4497C59.3282 32.8336 62.6856 29.2709 64.1186 25.3909L52.4922 30.7113C51.8681 31.793 51.2267 32.8218 50.5044 33.7683Z" fill={color}/>
    <path d="M44.2002 39.324C47.4015 38.7008 50.4468 37.6779 53.0934 36.3669C51.955 35.8848 50.73 35.438 49.4702 35.0559C47.962 36.7549 46.1533 38.2364 44.2002 39.324Z" fill={color}/>
    <path d="M41.5015 33.4507C40.7041 35.7552 39.6928 37.8892 38.5371 39.7176C42.0389 39.0239 45.2633 37.172 47.7654 34.5618C45.8123 34.0562 43.68 33.6741 41.5015 33.4507Z" fill={color}/>
    <path d="M36.3932 33.1628C35.1854 33.1628 33.9662 33.2158 32.8047 33.2981C33.7235 35.8436 35.0294 38.1305 36.37 40C37.7164 38.1364 38.9704 35.826 39.8892 33.2922C38.7624 33.204 37.572 33.1628 36.3932 33.1628Z" fill={color}/>
    <path d="M24.9575 34.5794C27.4365 37.1603 30.6667 39.0239 34.157 39.7117C33.0012 37.8893 31.9842 35.7376 31.1868 33.4507C29.0141 33.6917 26.9049 34.0856 24.9575 34.5794Z" fill={color}/>
    <path d="M44.6276 17.5838C44.7143 17.043 44.4542 16.7255 43.7839 16.7784L43.3217 16.8137L42.9634 18.5832L43.7204 18.5303C44.2636 18.4951 44.5409 18.1423 44.6276 17.5838Z" fill={color}/>
    <path d="M55.7689 16.8958C55.8556 16.355 55.5955 16.0904 54.9946 16.1257L54.2376 16.1609L53.9082 17.7541L54.7345 17.7012C55.2893 17.6659 55.6822 17.4719 55.7689 16.8958Z" fill={color}/>
    <path d="M32.1401 8.69482C31.8107 9.82357 31.5275 10.9876 31.2964 12.2222L41.299 11.6989C41.0852 10.6584 40.8425 9.67072 40.5594 8.69482C39.1841 8.8124 37.7972 8.84768 36.3931 8.84768C34.9542 8.8418 33.5385 8.80652 32.1401 8.69482Z" fill={color}/>
    <path d="M21.9062 12.7103L29.6842 12.3164C29.8922 11.2582 30.2505 9.60621 30.5336 8.60092C28.0431 8.31285 26.6042 8.16588 24.3621 7.47217C23.6514 8.84195 22.5477 11.2229 21.9062 12.7103Z" fill={color}/>
    <path d="M42.166 8.53637C42.4492 9.54167 42.6861 10.5528 42.8883 11.611L51.6428 11.1525C51.0014 9.68864 50.1982 8.30122 49.2736 7.06665C47.0373 7.76624 44.6566 8.24831 42.166 8.53637Z" fill={color}/>
    <path d="M17.7345 4.76758C14.4523 6.70761 12.2507 8.66529 10.2744 11.5342L20.8954 6.20791C19.4392 5.62002 18.8324 5.34959 17.7345 4.76758Z" fill={color}/>
    <path d="M48.2508 5.82018C45.6562 2.92189 42.1255 0.858397 38.3926 0.123535C39.6581 2.11648 40.8831 4.4798 41.7095 7.04299C44.0035 6.7902 46.2167 6.36104 48.2508 5.82018Z" fill={color}/>
    <path d="M49.921 5.33222C51.1923 4.93245 52.3942 4.42687 53.5326 3.92128C51.0074 2.60441 47.6443 1.39924 44.4834 0.864258C46.3903 2.01652 48.4648 3.56267 49.921 5.33222Z" fill={color}/>
    <path d="M53.3243 11.064L61.8419 10.6172C60.1545 8.41845 57.6755 6.2609 55.0001 4.74414C53.6537 5.40258 52.3708 6.02574 50.8511 6.5372C51.8161 7.90111 52.6771 9.46489 53.3243 11.064Z" fill={color}/>
    <path d="M28.6152 0.928955C25.4486 1.46981 21.7157 2.67499 19.1904 3.98598C20.3288 4.49157 22.0913 5.18527 23.3625 5.58504C24.8187 3.82137 26.7025 2.08122 28.6152 0.928955Z" fill={color}/>
    <path d="M36.3935 7.31922C37.6417 7.31922 38.8841 7.27219 40.0802 7.18401C39.1556 4.47384 37.815 1.98707 36.3935 0C34.9604 1.98707 33.5447 4.47384 32.6201 7.17813C33.8452 7.27219 35.1164 7.31922 36.3935 7.31922Z" fill={color}/>
    <path d="M25.1309 6.13764C27.1649 6.6785 28.754 6.7902 31.0423 7.04299C31.8686 4.48567 33.0937 2.11648 34.3592 0.123535C30.6263 0.793729 27.0956 3.51566 25.1309 6.13764Z" fill={color}/>
</svg>
  )
}

export default WarpLogo