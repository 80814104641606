import React from 'react'
import ReactPlayer from 'react-player'
import classNames from 'classnames'
import disableScroll from 'disable-scroll'
import CloseButton from './close-button'
import Div100vh from 'react-div-100vh'

class OverlayVideo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isReady: false,
      isPlaying: false,
    }
  }

  onPlay = () => {
    this.setState({
      isPlaying: true,
    })
  }

  onPause = () => {
    this.setState({
      isPlaying: false,
    })
  }

  togglePlay = () => {
    this.setState({
      isPlaying: !this.state.isPlaying,
    })
  }

  onReady = () => {
    this.setState({
      isReady: true,
    })
  }

  ref = player => {
    this.player = player
  }

  render() {
    const {
      title,
      artist,
      category,
      productionCompany,
      additionalInfo,
      videoUrl,
      currentVideoUrl,
    } = this.props
    const _videoUrl = currentVideoUrl ? currentVideoUrl : videoUrl
    const { isReady, isPlaying } = this.state
    const classes = classNames({
      'is-paused': !isPlaying,
      'is-playing': isPlaying,
      'is-video-init': isReady,
    })

    return (
      <Div100vh className={`overlay-video ${classes}`}>
        <button
          className="overlay-video__close-button-container"
          onClick={() => this.props.onCloseModal()}
        >
          <CloseButton className="overlay-video__close-button" stroke="white" />
        </button>
        <div className="overlay-video__text text-m text-caps">
          <span>{artist && <span className="is-artist">{artist}</span>}</span>
          <br />
          {title && <span className="is-italic">{category ? `${title}, ${category}` : title}</span>}
          {productionCompany ? (
            <>
              <br />
              {productionCompany}
            </>
          ) : (
            <></>
          )}
        </div>
        {_videoUrl && (
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              ref={this.ref}
              url={_videoUrl}
              width="100%"
              height="100%"
              playing={this.state.isPlaying}
              onReady={this.onReady}
              controls
            />
          </div>
        )}
      </Div100vh>
    )
  }
  componentDidMount() {
    disableScroll.on()
  }
  componentWillUnmount() {
    disableScroll.off()
  }
}

export default OverlayVideo
