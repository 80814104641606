import React, { useEffect } from 'react'
import { isBrowser, inheritClassNames } from '../lib/helpers'
import { warpCUrl } from '../variables'
import CloseButton from './close-button'
import WarpLogo from './warp-logo'

const BusinessCard = props => {
  const { id } = props
  let declinedBusinessCards = isBrowser()
    ? JSON.parse(localStorage.getItem('declinedBusinessCards')) || []
    : []
  const [isShown, setIsShown] = React.useState(!declinedBusinessCards.includes(id))
  const businessCardRef = React.useRef()

  useEffect(() => {
    if (isShown) {
      setTimeout(() => {
        businessCardRef.current.classList.remove('--outside')
      }, 100)
    }
    return () => {
      if (businessCardRef.current) {
        businessCardRef.current.classList.add('--outside')
      }
    }
  }, [])

  const handleClose = () => {
    if (id && isShown) {
      declinedBusinessCards.push(id)
      if (isBrowser()) {
        localStorage.declinedBusinessCards = JSON.stringify(declinedBusinessCards)
      }
      businessCardRef.current.classList.add('--outside')
      setTimeout(() => {
        setIsShown(false)
      }, 1000)
    }
  }
  return (
    isShown && (
      <div ref={businessCardRef} className="business-card --outside mob-p-1 tab-p-2_3">
        <div className="business-card__inner-wrapper mob-p-1 tab-p-1">
          <WarpLogo />
          <div className="business-card__inner-wrapper__content mob-ml-1 tab-ml-0">
            <span className="text-xs text-caps">For original scores please check out:</span>
            <a
              href={warpCUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-m-plus text-caps"
            >
              warpcomposers.net
            </a>
          </div>
          <div className="business-card__inner-wrapper__button-wrapper">
            <CloseButton onClick={handleClose} />
          </div>
        </div>
      </div>
    )
  )
}

export default BusinessCard
