import React, { useState } from 'react'
import clientConfig from '../../client-config'
import BasePortableText from '@sanity/block-content-to-react'
import serializers from './serializers'
import ColumnInfoEntries from './column-info-entries'
import { addColon } from '../lib/helpers'
import { Link } from 'gatsby'
import ShowMore from './show-more'
import classNames from 'classnames'
import CTALink from '../components/CTALink'

function CustomColumn({
  title,
  _rawBody,
  useRaw,
  infoList,
  className,
  body: body_,
  bodyTextSize,
  cta,
  hideColon = false,
  sticky,
  showMore,
  justifyText = false,
  showMoreDesktop = false,
  customClasses,
}) {
  const body = useRaw ? _rawBody : body_
  const firstParagraph = body && body.slice(0, 1)
  const otherParagraphs = body && body.slice(1)
  const isSyncScore = customClasses && customClasses.includes('is-sync-score')
  const [CTAHovered, setCTAHovered] = useState(false)

  const getBodyTextSizeClass = bodyTextSize => {
    switch (bodyTextSize) {
      case 's':
        return 'text-m'
      case 'm':
        return 'text-m-plus'
      case 'l':
        return 'text-l-minus'
      default:
        return 'text-m-plus'
    }
  }

  const bodyTextSizeClass = getBodyTextSizeClass(bodyTextSize)

  const ContentWrapper = ({ children }) => {
    return isSyncScore ? (
      <a className="synch-score-cta" href={cta.link} target="_blank" rel="noopener noreferrer">
        <div className="custom-column__content-wrapper custom-column__content-wrapper--is-synch-score mob-p-1 mob-pb-1_4 mob-pt-1_4 tab-p-2_4">
          {children}
        </div>
      </a>
    ) : (
      <div className="custom-column__content-wrapper mob-p-1 mob-pb-1_4 mob-pt-1_4 tab-p-2_4">
        {children}
      </div>
    )
  }

  const renderCTA = () => {
    return isSyncScore ? (
      <a className="custom-column__content-wrapper__cta-wrapper mob-mt-1_2 tab-mt-2_3 tab-mb-2_3">
        <span>
          <span className={`${bodyTextSizeClass} --link-internal --hover-theme-bg`}>
            {cta.title}
          </span>
        </span>
      </a>
    ) : (
      <div className="custom-column__content-wrapper__cta-wrapper mob-mt-1_2 tab-mt-2_3 tab-mb-2_3">
        <CTALink className={`rounded-button --link-internal --hover-theme-bg`} {...cta} inactive />
      </div>
    )
  }

  return (
    <div
      className={`${className ? className : ''} column custom-column ${classNames({
        'show-all-on-desktop': !showMoreDesktop,
        'custom-column--text-justified': justifyText,
        'is-cta-hovered': CTAHovered,
      })} ${customClasses ? customClasses.join(' ') : ''}`}
    >
      <ContentWrapper>
        <div className={`${classNames({ 'custom-column__sticky-inner': sticky })}`}>
          {title && <h3 className="text-xs text-caps">{hideColon ? title : addColon(title)}</h3>}
          {firstParagraph && showMore ? (
            <div className={`custom-column__body ${bodyTextSizeClass}`}>
              <div className="par-mb">
                {
                  <BasePortableText
                    blocks={firstParagraph}
                    serializers={serializers}
                    {...clientConfig.sanity}
                  />
                }
              </div>
              {otherParagraphs.length ? (
                <ShowMore
                  className={`custom-column__body custom-column__body--not-first-paragraphs ${bodyTextSizeClass}`}
                >
                  {
                    <BasePortableText
                      className="par-mb"
                      blocks={otherParagraphs}
                      serializers={serializers}
                      {...clientConfig.sanity}
                    />
                  }
                </ShowMore>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className={`custom-column__body ${bodyTextSizeClass}`}>
              <BasePortableText
                blocks={body}
                className="par-mb"
                serializers={serializers}
                {...clientConfig.sanity}
              />
            </div>
          )}
          {cta && renderCTA()}
          {infoList && (
            <div className="custom-column__content-wrapper__info-list">
              <ColumnInfoEntries entries={infoList} />
            </div>
          )}
        </div>
      </ContentWrapper>
    </div>
  )
}

export default CustomColumn
