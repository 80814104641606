import React, { useState } from 'react'
import Slider from './slider'
import classNames from 'classnames'
import { Link } from 'gatsby'
import ImageLoader from './image-loader'
import { getProjectUrl } from '../lib/helpers'
import Modal from 'react-modal'
import { CSSTransition } from 'react-transition-group'
import OverlayVideo from './overlay-video'
import modalStyle from '../styles/common/modal'

function SliderItem(props) {
  const { item, posterImage, modalOpen, onOpenModal, onCloseModal } = props
  let slug,
    smallTitle,
    dotLeft,
    dotRight,
    href,
    imageId,
    information,
    imageIsSquare,
    isOverlayVideo,
    overlayVideoUrl
  switch (item._type) {
    case 'album':
      href = item.streamingLink
      smallTitle = item.releaseID
      dotLeft = item.artist
      dotRight = item.title
      imageId = posterImage ? item.posterImage?.asset?._id : item.coverImage?.asset?._id
      ;(information = null), (imageIsSquare = true)
      break
    case 'project':
      slug = getProjectUrl(item?.slug.current)
      smallTitle = item.category?.title
      dotLeft = item.artist?.title
      dotRight = item.title
      imageId = posterImage ? item.posterImage?.asset?._id : item.previewImage?.asset?._id
      information = null
      imageIsSquare = false
      isOverlayVideo = item.isOverlayVideo
      overlayVideoUrl = item.overlayVideoUrl
      break
    case 'externalProject':
      smallTitle = item.category
      dotLeft = item.artist
      dotRight = item.projectTitle
      imageId = item.posterImage?.asset?._id
      imageIsSquare = false
      break
    default:
      throw new Error('category not recognized')
  }

  const linkClasses = classNames({
    '--square': imageIsSquare,
  })

  const renderVideoOverlay = overlayVideoUrl => {
    return (
      <CSSTransition in={modalOpen} timeout={400}>
        <Modal
          closeTimeoutMS={400}
          isOpen={modalOpen}
          onRequestClose={onCloseModal}
          style={modalStyle}
        >
          <OverlayVideo videoUrl={overlayVideoUrl} onCloseModal={onCloseModal} />
        </Modal>
      </CSSTransition>
    )
  }

  const linkContent = (
    <div className={`projects-slider-block-item image-filter ${linkClasses}`}>
      {smallTitle && (
        <h1 className="projects-slider-block-item__small_title text-xs text-caps">{smallTitle}:</h1>
      )}
      <ul className="text-m-minus text-caps">
        {dotLeft && <li className="projects-slider-block-item__dot-left">{dotLeft}</li>}
        <br />
        {dotRight && (
          <li className="projects-slider-block-item__dot-right is-italic">{dotRight}</li>
        )}
        {information && <li className="projects-slider-block-item__information">{information}</li>}
      </ul>
      <div className="projects-slider-block-item__auto-gap"></div>
      <div className="projects-slider-block-item__image image-filter__image-wrapper">
        {imageId && (
          <ImageLoader
            imageId={imageId}
            widths={[250, 350, 720]}
            alt={`Cover for ${dotLeft} - ${dotRight}`}
            scaleOffset
          />
        )}
      </div>
    </div>
  )
  if (isOverlayVideo && overlayVideoUrl) {
    return (
      <>
        <div
          className="projects-slider-block__overlay-preview-wrapper"
          onClick={onOpenModal}
          role="button"
          aria-label={`Open overlay video for ${dotLeft} - ${dotRight}`}
        >
          {linkContent}
        </div>
        {renderVideoOverlay(overlayVideoUrl)}
      </>
    )
  }
  return slug !== undefined ? (
    <Link to={slug}>{linkContent}</Link>
  ) : (
    <a rel="noreferrer" target="_blank" href={href}>
      {linkContent}
    </a>
  )
}

function RelatedProjectsSliderBlock(props) {
  const { items, posterImage = false, hideCategoryTitles = false, className } = props
  const [modalOpen, setModalOpen] = useState(false)
  const onOpenModal = () => setModalOpen(true)
  const onCloseModal = () => setModalOpen(false)

  return (
    <Slider className={`${className} grow-out`}>
      {items.map((category, categoryIndex) => (
        <div key={categoryIndex} className="projects-slider-block__category">
          {!hideCategoryTitles && (
            <h2
              className="projects-slider-block__category__title text-m-plus text-caps"
              style={category.items && { gridColumn: `span ${category.items.length}` }}
              data-category={category.title}
            >
              <span className="mob-p-1 mob-pb-0_2-i">{category.title}</span>
            </h2>
          )}
          <ul className="projects-slider-block__category__items">
            {category.items &&
              category.items.map((item, index) => {
                if (item) {
                  return (
                    <li key={index}>
                      <SliderItem
                        item={item}
                        posterImage={posterImage}
                        modalOpen={modalOpen}
                        onOpenModal={onOpenModal}
                        onCloseModal={onCloseModal}
                      />
                    </li>
                  )
                }
              })}
          </ul>
        </div>
      ))}
      <div className="projects-slider-block__filler"></div>
    </Slider>
  )
}

export default RelatedProjectsSliderBlock
