import React, { useState } from 'react'
import { inheritClassNames } from '../lib/helpers'
import AnimateHeight from 'react-animate-height'

export const ShowMore = props => {
  const { children } = props
  const [showMore, setShowMore] = useState(true)

  return (
    <>
      <AnimateHeight height={showMore ? 0 : 'auto'} className={inheritClassNames(props)}>
        {children}
      </AnimateHeight>
      <button
        className="rounded-button "
        onClick={() => {
          setShowMore(!showMore)
        }}
      >
        {showMore ? 'Read more' : 'Show less'}
      </button>
    </>
  )
}

export default ShowMore
